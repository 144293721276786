export const LANGS = [
    ["", "AMERICA"], 
    ["es-ar", "Argentina - Español"], 
    ["pt-br", "Brasil - Português"], 
    ["en-ca", "Canada - English"], 
    ["fr-ca", "Canada - Français"], 
    ["es-cl", "Chile - Español"], 
    ["es-co", "Colombia - Español"], 
    ["es-mx", "México - Español"], 
    ["en-us", "United States - English"], 
    ["", "EUROPE"], 
    ["nl-be", "België - Nederlands"], 
    ["fr-be", "Belgique - Français"], 
    ["cs-cz", "Česká Republika - Čeština"], 
    ["da-dk", "Danmark - Dansk"], 
    ["de-de", "Deutschland - Deutsch"], 
    ["es-es", "España - Español"], 
    ["fr-fr", "France - Français"], 
    ["en-ie", "Ireland - English"], 
    ["it-it", "Italia - Italiano"], 
    ["hu-hu", "Magyarország - Magyar"], 
    ["nl-nl", "Nederland - Nederlands"], 
    ["nb-no", "Norge - Norsk bokmål"], 
    ["de-at", "Österreich - Deutsch"], 
    ["pl-pl", "Polska - Polski"], 
    ["pt-pt", "Portugal - Português"], 
    ["de-ch", "Schweiz - Deutsch"], 
    ["sk-sk", "Slovensko - Slovenčina"], 
    ["fr-ch", "Suisse - Français"], 
    ["fi-fi", "Suomi - Suomi"], 
    ["sv-se", "Sverige - Svenska"], 
    ["en-gb", "United Kingdom - English"], 
    ["el-gr", "Ελλάδα - Ελληνικά"], 
    ["ru-ru", "Россия - Русский"], 
    ["", "ASIA + PACIFIC"], 
    ["en-au", "Australia - English"], 
    ["en-hk", "Hong Kong/Macau (SAR) - English"], 
    ["en-in", "India - English"], 
    ["en-nz", "New Zealand - English"], 
    ["en-sg", "Singapore - English"], 
    ["ko-kr", "대한민국 - 한국어"], 
    ["zh-cn", "中国 - 中文"], 
    ["zh-tw", "台灣 - 繁體中文"], 
    ["ja-jp", "日本 - 日本語"], 
    ["zh-hk", "香港/澳門特別行政區 - 繁體中文"], 
    ["", "MIDDLE EAST + AFRICA"], 
    ["en-za", "South Africa - English"], 
    ["tr-tr", "Türkiye - Türkçe"], 
    ["he-il", "ישראל - עברית"], 
    ["ar-ae", "الإمارات العربية المتحدة - العربية"], 
    ["ar-sa", "المملكة العربية السعودية - العربية"]
]